import React from 'react'
import { Text, types } from 'react-bricks'
import LayoutContainer from '../../components/LayoutContainer'

interface PageHeaderProps {
  title: string
  bgImage: types.IImageSource
}

const PageHeader: types.Brick<PageHeaderProps> = ({ bgImage }) => {
  return (
    <div
      className="w-full h-56 lg:h-72 bg-themegray bg-cover"
      style={{ backgroundImage: `url(${bgImage?.src})` }}
    >
      <LayoutContainer className="h-full flex items-end">
        <Text
          renderBlock={(props) => (
            <h1 className="pb-20 lg:pb-24 text-[40px] md:text-[64px] text-white">
              {props.children}
            </h1>
          )}
          placeholder="Page title..."
          propName="title"
          renderPlaceholder={(props) => (
            <span className="opacity-30">{props.children}</span>
          )}
        />
      </LayoutContainer>
    </div>
  )
}

PageHeader.schema = {
  name: 'page-header',
  label: 'Page Header',
  getDefaultProps: () => ({
    title: 'New Page',
    bgImage: {
      src: 'https://images.reactbricks.com/original/ff06bfcc-7a42-47b5-8706-270db5eaecfe.jpg',
      placeholderSrc:
        'https://images.reactbricks.com/placeholder/ff06bfcc-7a42-47b5-8706-270db5eaecfe.jpg',
      srcSet:
        'https://images.reactbricks.com/src_set/ff06bfcc-7a42-47b5-8706-270db5eaecfe-1600.jpg 1600w,\nhttps://images.reactbricks.com/src_set/ff06bfcc-7a42-47b5-8706-270db5eaecfe-1200.jpg 1200w,\nhttps://images.reactbricks.com/src_set/ff06bfcc-7a42-47b5-8706-270db5eaecfe-800.jpg 800w,\nhttps://images.reactbricks.com/src_set/ff06bfcc-7a42-47b5-8706-270db5eaecfe-400.jpg 400w,\nhttps://images.reactbricks.com/src_set/ff06bfcc-7a42-47b5-8706-270db5eaecfe-200.jpg 200w',
      alt: 'Page Header Background',
      seoName: 'on-the-road',
      width: 2880,
      height: 598,
    },
  }),
  sideEditProps: [
    {
      name: 'bgImage',
      label: 'Background Image',
      type: types.SideEditPropType.Image,
    },
  ],
}

export default PageHeader
