import React, { useState } from 'react'
import { types, Repeater } from 'react-bricks/frontend'
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi'

import LayoutContainer from '../../components/LayoutContainer'

interface VideoCarouselProps {
  paddingTop?: boolean
  paddingBottom?: boolean
}

const VideoCarousel: types.Brick<VideoCarouselProps> = ({
  paddingTop,
  paddingBottom,
}) => {
  const repeaterElement = Repeater({ propName: 'videoListings' })
  const [carouselIdx, setCarouselIdx] = useState<number>(0)

  return (
    <section
      className={`w-screen ${paddingTop ? 'pt-20' : ''} ${
        paddingBottom ? 'pb-20' : 'pb-5'
      }`}
    >
      <LayoutContainer>
        <div className="bg-themegray-footer flex flex-col justify-between space-y-8 py-10 min-h-[500px]">
          <div className="flex flex-row h-full w-full flex-grow items-center">
            <div
              className="flex items-center flex-shrink-0 justify-center rounded-full h-16 w-16 cursor-pointer hover:bg-white hover:bg-opacity-20 mx-2"
              onClick={() =>
                setCarouselIdx((prev) =>
                  prev - 1 >= 0
                    ? prev - 1
                    : repeaterElement?.props?.children?.length - 1
                )
              }
            >
              <FiArrowLeft className="text-white text-4xl mx-2" />
            </div>
            <div className="relative flex flex-row flex-grow overflow-hidden">
              {repeaterElement?.props?.children?.map((child, idx) => (
                <div className={`${idx === carouselIdx ? 'block' : 'hidden'}`}>
                  {child}
                </div>
              ))}
            </div>
            <div
              className="flex flex-shrink-0 justify-center items-center rounded-full h-20 w-20 cursor-pointer hover:bg-white hover:bg-opacity-20 mx-2"
              onClick={() =>
                setCarouselIdx((prev) =>
                  prev + 1 < repeaterElement?.props?.children?.length
                    ? prev + 1
                    : 0
                )
              }
            >
              <FiArrowRight className="text-white text-4xl mx-2" />
            </div>
          </div>
          <div className="px-10">
            <Repeater
              propName="buttons"
              renderItemWrapper={(item) => (
                <div className="overflow-hidden">{item}</div>
              )}
            />
          </div>
        </div>
      </LayoutContainer>
    </section>
  )
}

VideoCarousel.schema = {
  name: 'video-carousel',
  label: 'Video Carousel',
  getDefaultProps: () => ({
    paddingTop: true,
    paddingBottom: true,
  }),
  sideEditProps: [
    {
      name: 'paddingTop',
      label: 'Padding Top',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'paddingBottom',
      label: 'Padding Bottom',
      type: types.SideEditPropType.Boolean,
    },
  ],
  repeaterItems: [
    {
      name: 'buttons',
      itemType: 'button',
      itemLabel: 'Button',
      max: 1,
    },
    {
      name: 'videoListings',
      itemType: 'video-listing',
      itemLabel: 'Video Listing',
    },
  ],
}

export default VideoCarousel
