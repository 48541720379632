import React from 'react'
import { types, Image, Repeater } from 'react-bricks/frontend'
import LayoutContainer from '../../components/LayoutContainer'
import RotatingTitles from '../../components/RotatingTitles'

interface HeroProps {
  label: string
  titles: string
}

const Hero: types.Brick<HeroProps> = ({ label, titles }) => {
  return (
    <div className="relative h-[530px] lg:h-[645px]">
      <Image
        propName="bgImage"
        alt="hero background image"
        containerClassName="hidden sm:block absolute inset-0"
        imageClassName="h-full w-full object-cover object-center"
        aspectRatio={2880 / 1310}
      />
      <Image
        propName="bgImageMobile"
        alt="hero background image mobile"
        containerClassName="block absolute sm:hidden inset-0"
        imageClassName="h-full w-full object-cover object-center"
        maxWidth={2880}
      />
      <LayoutContainer className="relative flex flex-col sm:flex-row sm:justify-between justify-start sm:items-center items-start h-full w-full">
        <div className="flex flex-col justify-center py-8 sm:py-16 flex-grow md:flex-grow-0">
          <div className="flex flex-col">
            <span className="uppercase font-bold text-white">{label}</span>
            <div className="relative mt-3 mb-12 w-[200px] sm:w-[600px] h-52 overflow-hidden">
              <RotatingTitles titles={titles} />
            </div>
          </div>
          <div className="flex flex-col flex-grow md:flex-grow-0 justify-end sm:justify-start space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
            <Repeater propName="buttons" />
          </div>
        </div>
        <div className="flex flex-row space-x-2 sm:flex-col sm:space-y-2 sm:space-x-0 mb-10 sm:mb-0">
          <Repeater propName="socialIcons" />
        </div>
      </LayoutContainer>
    </div>
  )
}

Hero.schema = {
  name: 'hero',
  label: 'Hero',
  getDefaultProps: () => ({
    label: 'i am your:',
    bgImage: {
      src: 'https://images.reactbricks.com/original/ec48d38d-f928-4a24-9978-1f98e1073a89.jpg',
      placeholderSrc:
        'https://images.reactbricks.com/placeholder/ec48d38d-f928-4a24-9978-1f98e1073a89.jpg',
      srcSet:
        'https://images.reactbricks.com/src_set/ec48d38d-f928-4a24-9978-1f98e1073a89-1600.jpg 1600w,\nhttps://images.reactbricks.com/src_set/ec48d38d-f928-4a24-9978-1f98e1073a89-1200.jpg 1200w,\nhttps://images.reactbricks.com/src_set/ec48d38d-f928-4a24-9978-1f98e1073a89-800.jpg 800w,\nhttps://images.reactbricks.com/src_set/ec48d38d-f928-4a24-9978-1f98e1073a89-400.jpg 400w,\nhttps://images.reactbricks.com/src_set/ec48d38d-f928-4a24-9978-1f98e1073a89-200.jpg 200w',
      alt: 'Hero Background Image',
      seoName: 'rhonda-sawchuk-hero',
      width: 2880,
      height: 1310,
    },
    bgImageMobile: {
      src: 'https://images.reactbricks.com/original/0cc5f692-47d3-4b20-b5f0-b9c7d54c7fcb.jpg',
      placeholderSrc:
        'https://images.reactbricks.com/placeholder/0cc5f692-47d3-4b20-b5f0-b9c7d54c7fcb.jpg',
      srcSet:
        'https://images.reactbricks.com/src_set/0cc5f692-47d3-4b20-b5f0-b9c7d54c7fcb-640.jpg 640w,\nhttps://images.reactbricks.com/src_set/0cc5f692-47d3-4b20-b5f0-b9c7d54c7fcb-400.jpg 400w,\nhttps://images.reactbricks.com/src_set/0cc5f692-47d3-4b20-b5f0-b9c7d54c7fcb-200.jpg 200w',
      alt: 'Hero Background Image',
      seoName: 'rhonda-sawchuk-hero',
      width: 640,
      height: 1096,
    },
    titles: 'Default title.',
  }),
  sideEditProps: [
    {
      name: 'bgImage',
      label: 'Background Image',
      type: types.SideEditPropType.Image,
    },
    {
      name: 'bgImageMobile',
      label: 'Background Image - Mobile (shown on small screens)',
      type: types.SideEditPropType.Image,
    },
    {
      name: 'titles',
      label: 'Titles (separate titles by semicolons)',
      type: types.SideEditPropType.Textarea,
    },
  ],
  repeaterItems: [
    {
      name: 'buttons',
      itemType: 'button',
      itemLabel: 'Button',
      max: 2,
    },
    {
      name: 'socialIcons',
      itemType: 'social-icon',
      itemLabel: 'Social Link',
    },
  ],
}

export default Hero
