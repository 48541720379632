import React from 'react'

export default function DubsadoContactForm() {
  return (
    <div
      className="bg-themegray-footer rounded-lg"
      dangerouslySetInnerHTML={{
        __html: `
        <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.14/iframeResizer.min.js"></script>
        <iframe src="https://hello.dubsado.com:443/public/form/view/62a11ae1fdbfd1c2cb0c92d0" frameborder="0" style="width:1px; min-width:100%; min-height:1000px;">
          <style>
            color: white;
          </style>
        </iframe>
        <script type="text/javascript">setTimeout(function(){iFrameResize({checkOrigin: false, heightCalculationMethod: "taggedElement"});}, 30)</script>
      `,
      }}
    ></div>
  )
}
