import React from 'react'
import { types, Repeater } from 'react-bricks/frontend'
import LayoutContainer from '../../components/LayoutContainer'

interface ArrowCardsProps {
  paddingTop?: boolean
  paddingBottom?: boolean
}

const ArrowCards: types.Brick<ArrowCardsProps> = ({
  paddingTop,
  paddingBottom,
}) => (
  <section
    className={`w-screen ${paddingTop ? 'pt-20' : ''} ${
      paddingBottom ? 'pb-20' : 'pb-5'
    }`}
  >
    <LayoutContainer>
      <div className="flex flex-col sm:flex-row space-y-6 sm:space-x-6 sm:space-y-0">
        <Repeater
          propName="cards"
          renderItemWrapper={(item) => (
            <div className="flex flex-col basis-1/2">{item}</div>
          )}
        />
      </div>
    </LayoutContainer>
  </section>
)

ArrowCards.schema = {
  name: 'arrow-cards',
  label: 'Arrow Cards',
  getDefaultProps: () => ({
    paddingTop: true,
    paddingBottom: true,
  }),
  sideEditProps: [
    {
      name: 'paddingTop',
      label: 'Padding Top',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'paddingBottom',
      label: 'Padding Bottom',
      type: types.SideEditPropType.Boolean,
    },
  ],
  repeaterItems: [
    {
      name: 'cards',
      itemType: 'arrow-card',
      itemLabel: 'Card',
    },
  ],
}

export default ArrowCards
