import React from 'react'
import { types, Repeater } from 'react-bricks/frontend'
import LayoutContainer from '../../components/LayoutContainer'
import HorizontalDivider from '../../components/HorizontalDivider'

interface PDFListProps {
  paddingTop?: boolean
  paddingBottom?: boolean
}

const PDFList: types.Brick<PDFListProps> = ({ paddingTop, paddingBottom }) => (
  <section
    className={`w-screen ${paddingTop ? 'pt-20' : ''} ${
      paddingBottom ? 'pb-20' : 'pb-5'
    }`}
  >
    <LayoutContainer>
      <HorizontalDivider className="bg-themegray-light my-8" />
      <Repeater
        propName="pdfs"
        renderItemWrapper={(item) => (
          <>
            {item}
            <HorizontalDivider className="bg-themegray-light my-8" />
          </>
        )}
      />
    </LayoutContainer>
  </section>
)

PDFList.schema = {
  name: 'pdf-list',
  label: 'PDF List',
  getDefaultProps: () => ({
    paddingTop: true,
    paddingBottom: true,
  }),
  sideEditProps: [
    {
      name: 'paddingTop',
      label: 'Padding Top',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'paddingBottom',
      label: 'Padding Bottom',
      type: types.SideEditPropType.Boolean,
    },
  ],
  repeaterItems: [
    {
      name: 'pdfs',
      itemType: 'pdf-download',
      itemLabel: 'PDF Download',
    },
  ],
}

export default PDFList
