import React, { useState } from 'react'
import { types, RichText, useAdminContext } from 'react-bricks/frontend'
import { FiArrowRight } from 'react-icons/fi'
import GatsbyLink from '../../react-bricks/GatsbyLink'
import ModalVideo from 'react-modal-video'

interface ButtonProps {
  bgColor: types.IColor
  textColor: types.IColor
  arrowColor: types.IColor
  padding: boolean
  relativeUrl?: string
  externalUrl?: string
  videoId?: string
}

const Button: types.Brick<ButtonProps> = ({
  bgColor,
  textColor,
  arrowColor,
  padding,
  relativeUrl,
  externalUrl,
  videoId,
  ...rest
}) => {
  const { isAdmin } = useAdminContext()

  const [open, setOpen] = useState<boolean>(false)

  const elem = (
    <div {...rest}>
      <button
        type="button"
        className={`group font-medium text-normal sm:text-xl py-2 sm:py-3 ${
          padding ? 'px-5 sm:px-9' : ''
          } rounded min-w-fit ${bgColor?.className} ${textColor?.className}`}
          onClick={() => {
            if (videoId) {
              setOpen(true)
            }
          }}
      >
        <RichText
          propName="text"
          renderBlock={({ children }) => (
            <div className="flex items-center whitespace-nowrap">
              {children}
              <FiArrowRight
                className={`ml-1 text-2xl transition-all duration-300 ease-in-out -rotate-45 group-hover:rotate-0 ${arrowColor?.className}`}
              />
            </div>
          )}
          placeholder="Type here..."
          allowedFeatures={[types.RichTextFeatures.Bold]}
        />
      </button>
    </div>
  )

  if (isAdmin) {
    return elem
  }

  if (relativeUrl) {
    return <GatsbyLink href={relativeUrl}>{elem}</GatsbyLink>
  }

  if (externalUrl) {
    return (
      <a href={externalUrl} target="_blank" rel="noreferrer">
        {elem}
      </a>
    )
  }

  if (videoId) {
    return (
      <>
        <ModalVideo
          channel="youtube"
          youtube={{ autoplay: 1, mute: 1 }}
          isOpen={open}
          videoId={videoId}
          onClose={() => setOpen(false)}
        />
        {elem}
      </>
    )
  }

  return elem
}

Button.schema = {
  name: 'button',
  label: 'Button',
  getDefaultProps: () => ({
    textColor: { color: '#ffffff', className: 'text-white' },
    arrowColor: { color: '#ffffff', className: 'text-white' },
    bgColor: { color: '#9c2a3b', className: 'bg-themered' },
    padding: true,
  }),
  sideEditProps: [
    {
      name: 'bgColor',
      label: 'Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'White',
            value: { color: '#ffffff', className: 'bg-white' },
          },
          {
            label: 'Red',
            value: { color: '#9c2a3b', className: 'bg-themered' },
          },
        ],
      },
    },
    {
      name: 'textColor',
      label: 'Text Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'White',
            value: { color: '#ffffff', className: 'text-white' },
          },
          {
            label: 'Red',
            value: { color: '#9c2a3b', className: 'text-themered' },
          },
          {
            label: 'Gray - Text',
            value: { color: '#444648', className: 'text-themegray-text' },
          },
          {
            label: 'Gray - Heading',
            value: { color: '#252729', className: 'text-themegray-heading' },
          },
        ],
      },
    },
    {
      name: 'arrowColor',
      label: 'Arrow Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'White',
            value: { color: '#ffffff', className: 'text-white' },
          },
          {
            label: 'Red',
            value: { color: '#9c2a3b', className: 'text-themered' },
          },
        ],
      },
    },
    {
      name: 'padding',
      label: 'Horizontal Padding',
      type: types.SideEditPropType.Boolean,
    },
    {
      groupName: 'Button Action',
      defaultOpen: true,
      props: [
        {
          name: 'relativeUrl',
          label: 'Relative Url (begins with "/")',
          type: types.SideEditPropType.Text,
          validate: (value, props) =>
            value && (props?.external || props?.videoId)
              ? 'You can only specify one Button Action'
              : true,
        },
        {
          name: 'externalUrl',
          label: 'External Url (begins with "https://www.")',
          type: types.SideEditPropType.Text,
          validate: (value, props) =>
            value && (props?.relativeUrl || props?.videoId)
              ? 'You can only specify one Button Action'
              : true,
        },
        {
          name: 'videoId',
          label: 'YouTube Video ID',
          type: types.SideEditPropType.Text,
          validate: (value, props) =>
            value && (props?.relativeUrl || props?.externalUrl)
              ? 'You can only specify one Button Action'
              : true,
        },
      ],
    },
  ],
}

export default Button
