import React from 'react'
import { types, Repeater } from 'react-bricks/frontend'
import LayoutContainer from '../../components/LayoutContainer'

interface OfferingsProps {
  paddingTop?: boolean
  paddingBottom?: boolean
}

const Offerings: types.Brick<OfferingsProps> = ({
  paddingTop,
  paddingBottom,
}) => (
  <section
    className={`${paddingTop ? 'pt-20' : ''} ${paddingBottom ? 'pb-20' : ''}`}
  >
    <LayoutContainer>
      <div className="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
        <Repeater propName="offerings" />
      </div>
    </LayoutContainer>
  </section>
)

Offerings.schema = {
  name: 'offerings',
  label: 'Offerings',
  getDefaultProps: () => ({
    paddingTop: true,
    paddingBottom: true,
  }),
  sideEditProps: [
    {
      name: 'paddingTop',
      label: 'Padding Top',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'paddingBottom',
      label: 'Padding Bottom',
      type: types.SideEditPropType.Boolean,
    },
  ],
  repeaterItems: [
    {
      name: 'offerings',
      itemType: 'icon-text',
      itemLabel: 'Offering',
    },
  ],
}

export default Offerings
