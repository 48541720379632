import React from 'react'
import {
  types,
  RichText,
  Repeater,
  useAdminContext,
} from 'react-bricks/frontend'
import { FaPhoneAlt } from 'react-icons/fa'
import { MdMail } from 'react-icons/md'
import HorizontalDivider from '../../components/HorizontalDivider'
import LayoutContainer from '../../components/LayoutContainer'
import ContactForm from './ContactForm'

interface ContactFormAndInfoProps {
  paddingTop?: boolean
  paddingBottom?: boolean
  phone: any
  email: any
}

const ContactFormAndInfo: types.Brick<ContactFormAndInfoProps> = ({
  paddingTop,
  paddingBottom,
  phone,
  email,
}) => {
  const { isAdmin } = useAdminContext()

  return (
    <section
      className={`w-screen ${paddingTop ? 'pt-20' : ''} ${
        paddingBottom ? 'pb-20' : 'pb-5'
      }`}
    >
      <LayoutContainer>
        <div className="flex flex-col-reverse space-y-8 space-y-reverse sm:space-y-0 sm:flex-row sm:space-x-8">
          <div className="flex flex-col basis-3/5">
            <ContactForm />
          </div>
          <div className="flex flex-col basis-2/5 space-y-8 content">
            <RichText
              propName="subheading"
              renderBlock={({ children }) => <h2>{children}</h2>}
              placeholder="Type here..."
              allowedFeatures={[types.RichTextFeatures.Highlight]}
              renderHighlight={({ children }) => (
                <span className="text-themered">{children}</span>
              )}
            />
            <div className="flex flex-col space-y-1">
              <a
                className="flex flex-row items-center hover:opacity-90"
                href={
                  isAdmin ? undefined : `tel:${phone?.[0]?.children?.[0]?.text}`
                }
              >
                <FaPhoneAlt className="text-themered mr-2" />
                <RichText
                  propName="phone"
                  renderBlock={({ children }) => (
                    <p className="mb-0">{children}</p>
                  )}
                  placeholder="Type here..."
                />
              </a>
              <a
                className="flex flex-row items-center hover:opacity-90"
                href={
                  isAdmin
                    ? undefined
                    : `mailto:${email?.[0]?.children?.[0]?.text}`
                }
              >
                <MdMail className="text-themered mr-2" />
                <RichText
                  propName="email"
                  renderBlock={({ children }) => (
                    <p className="mb-0">{children}</p>
                  )}
                  placeholder="Type here..."
                />
              </a>
            </div>
            <HorizontalDivider className="bg-themegray-extralight" />
            <div className="flex flex-row space-x-2 text-themered">
              <Repeater propName="socialIcons" />
            </div>
          </div>
        </div>
      </LayoutContainer>
    </section>
  )
}

ContactFormAndInfo.schema = {
  name: 'contact-form-and-info',
  label: 'Contact Form and Info',
  getDefaultProps: () => ({
    heading: 'Replace this heading',
    content: 'Replace this content',
    paddingTop: true,
    paddingBottom: true,
  }),
  sideEditProps: [
    {
      name: 'paddingTop',
      label: 'Padding Top',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'paddingBottom',
      label: 'Padding Bottom',
      type: types.SideEditPropType.Boolean,
    },
  ],
  repeaterItems: [
    {
      name: 'socialIcons',
      itemType: 'social-icon',
      itemLabel: 'Social Link',
    },
  ],
}

export default ContactFormAndInfo
