import React from 'react'
import { types, Image, RichText, Repeater } from 'react-bricks/frontend'
import LayoutContainer from '../../components/LayoutContainer'

interface BannerSectionProps {}

const BannerSection: types.Brick<BannerSectionProps> = () => (
  <section className="py-10 sm:py-20 w-screen">
    <LayoutContainer>
      <div className="relative min-h-[350px]">
        <Image
          propName="bgImage"
          alt="hero background image"
          containerClassName="h-full w-full absolute inset-0"
          imageClassName="h-full w-full object-cover object-left sm:object-center"
        />
        <div className="relative flex text-white">
          <div className="flex flex-col sm:basis-1/2 py-10 pl-10 pr-10 sm:pr-0">
            <RichText
              propName="heading"
              renderBlock={({ children }) => (
                <h3 className="text-2xl sm:text-4xl mb-4">{children}</h3>
              )}
              placeholder="Type here..."
              allowedFeatures={[types.RichTextFeatures.Highlight]}
              renderHighlight={({ children }) => (
                <h3
                  className="text-themered max-w-fit text-2xl sm:text-4xl mb-10"
                  style={{ lineHeight: 1.35 }}
                >
                  <span className="relative shadow bg-white whitespace-pre-wrap">
                    <span className="relative left-1.5 pr-2 text-themered">
                      {children}
                    </span>
                  </span>
                </h3>
              )}
            />
            <RichText
              propName="text"
              renderBlock={({ children }) => (
                <p className="content text-white mb-12">{children}</p>
              )}
              placeholder="Type here..."
              allowedFeatures={[
                types.RichTextFeatures.Bold,
                types.RichTextFeatures.Highlight,
                types.RichTextFeatures.Link,
                types.RichTextFeatures.Italic,
              ]}
              renderHighlight={({ children }) => (
                <span className="text-themered">{children}</span>
              )}
            />
            <div className="flex flex-col flex-grow md:flex-grow-0 justify-end sm:justify-start space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
              <Repeater propName="buttons" />
            </div>
          </div>
          <div className="hidden sm:block relative basis-1/2">
            <Image
              propName="sideImage"
              alt="rhonda sawchuk photo"
              containerClassName="absolute flex items-end inset-0"
              imageClassName="h-4/5 w-full object-left-bottom lg:object-bottom object-contain"
            />
          </div>
        </div>
      </div>
    </LayoutContainer>
  </section>
)

BannerSection.schema = {
  name: 'banner-section',
  label: 'Banner Section',
  getDefaultProps: () => ({
    heading: 'Ready to Improve your Quality of life?',
    text: 'Teaching strategies for success that will empower you to make changes that will impact your life forever.',
    bgImage: {
      src: 'https://images.reactbricks.com/original/ef249fd1-9c5a-4224-b734-492d7ab0ee8b.jpg',
      placeholderSrc:
        'https://images.reactbricks.com/placeholder/ef249fd1-9c5a-4224-b734-492d7ab0ee8b.jpg',
      srcSet:
        'https://images.reactbricks.com/src_set/ef249fd1-9c5a-4224-b734-492d7ab0ee8b-1600.jpg 1600w,\nhttps://images.reactbricks.com/src_set/ef249fd1-9c5a-4224-b734-492d7ab0ee8b-1200.jpg 1200w,\nhttps://images.reactbricks.com/src_set/ef249fd1-9c5a-4224-b734-492d7ab0ee8b-800.jpg 800w,\nhttps://images.reactbricks.com/src_set/ef249fd1-9c5a-4224-b734-492d7ab0ee8b-400.jpg 400w,\nhttps://images.reactbricks.com/src_set/ef249fd1-9c5a-4224-b734-492d7ab0ee8b-200.jpg 200w',
      alt: 'Hero Background Image',
      seoName: 'rhonda-sawchuk-hero',
      width: 2880,
      height: 598,
    },
  }),
  sideEditProps: [
    {
      name: 'bgImage',
      label: 'Background Image',
      type: types.SideEditPropType.Image,
    },
  ],
  repeaterItems: [
    {
      name: 'buttons',
      itemType: 'button',
      itemLabel: 'Button',
      max: 2,
    },
  ],
}

export default BannerSection
