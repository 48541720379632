import React, { useState } from 'react'
import useInterval from '../hooks/useInterval'

interface RotatingTitlesProps {
  titles: string
}

const RotatingTitles: React.FC<RotatingTitlesProps> = ({ titles }) => {
  if (!titles) {
    return null
  }

  const titlesArr =
    titles
      .trim()
      .split(';')
      .map((title) => title.trim())
      .filter((title) => title) || []
  const [titleIdx, setTitleIdx] = useState<number>(0)

  useInterval(
    () => {
      setTitleIdx((prev) => (prev + 1 < titlesArr.length ? prev + 1 : 0))
    },
    titlesArr.length > 1 ? 3000 : null
  )

  return (
    <>
      {titlesArr.length > 0 &&
        titlesArr.map((title, idx) => (
          <h2
            className={`absolute text-themered max-w-fit text-4xl sm:text-6xl mb-10 transition-all duration-300 ease-in-out ${
              idx === titleIdx ? 'translate-y-0' : 'translate-y-20 opacity-0'
            }`}
            style={{ lineHeight: 1.35 }}
          >
            <span className={`relative shadow bg-white whitespace-pre-wrap`}>
              <span className="relative left-1.5 pr-2 text-themered">
                {title}
              </span>
            </span>
          </h2>
        ))}
    </>
  )
}

export default RotatingTitles
