import React from 'react'
import { types, useAdminContext } from 'react-bricks/frontend'
import { FaLinkedin, FaYoutube, FaInstagram } from 'react-icons/fa'

interface SocialIconProps {
  icon: 'FaLinkedin' | 'FaYoutube' | 'FaInstagram'
  url: string
  iconColor: types.IColor
}

const SocialIcon: types.Brick<SocialIconProps> = ({
  icon,
  url,
  iconColor,
  ...rest
}) => {
  const { isAdmin } = useAdminContext()

  return (
    <a
      href={isAdmin ? undefined : url}
      target="_blank"
      rel="noreferrer"
      className="flex justify-center items-center h-12 w-12 rounded-full border-2 border-themegray-light hover:bg-themegray-text hover:bg-opacity-50"
      {...rest}
    >
      {icon === 'FaLinkedin' && (
        <FaLinkedin className={`${iconColor?.className}`} />
      )}
      {icon === 'FaYoutube' && (
        <FaYoutube className={`${iconColor?.className}`} />
      )}
      {icon === 'FaInstagram' && (
        <FaInstagram className={`${iconColor?.className}`} />
      )}
    </a>
  )
}

SocialIcon.schema = {
  name: 'social-icon',
  label: 'Social Icon',
  getDefaultProps: () => ({
    icon: 'Linkedin',
    url: '#',
    iconColor: { color: '#ffffff', className: 'text-white' },
  }),
  sideEditProps: [
    {
      name: 'icon',
      label: 'Social Icon',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Select,
        options: [
          {
            label: 'LinkedIn',
            value: 'FaLinkedin',
          },
          {
            label: 'YouTube',
            value: 'FaYoutube',
          },
          {
            label: 'Instagram',
            value: 'FaInstagram',
          },
        ],
      },
    },
    {
      name: 'url',
      label: 'Link',
      type: types.SideEditPropType.Text,
    },
    {
      name: 'iconColor',
      label: 'Icon Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'White',
            value: { color: '#ffffff', className: 'text-white' },
          },
          {
            label: 'Red',
            value: { color: '#9c2a3b', className: 'text-themered' },
          },
        ],
      },
    },
  ],
}

export default SocialIcon
