import React from 'react'
import { types, RichText, Image, Repeater } from 'react-bricks/frontend'
import HorizontalDivider from '../../components/HorizontalDivider'
import LayoutContainer from '../../components/LayoutContainer'
import smallQuoteRed from '../../images/small-quote-red.svg'

interface QuoteCtaProps {
  paddingTop?: boolean
  paddingBottom?: boolean
  bgColor: types.IColor
  btnMargin: boolean
  headingId: string
}

const QuoteCta: types.Brick<QuoteCtaProps> = ({
  paddingTop,
  paddingBottom,
  bgColor,
  btnMargin,
  headingId,
}) => (
  <section
    id={headingId}
    className={`w-screen ${paddingTop ? 'pt-20' : ''} ${
      paddingBottom ? 'pb-20' : ''
    }`}
  >
    <div className={`w-screen ${bgColor?.className}`}>
      <LayoutContainer className="w-full py-20 flex flex-col space-y-20">
        <div>
          <Repeater propName="banners" />
        </div>
        <div className="w-full flex flex-col space-y-8 sm:flex-row sm:space-y-0 sm:space-x-16">
          <div className="flex flex-col basis-1/2 space-y-4 content">
            <RichText
              propName="heading"
              renderBlock={({ children }) => <h2>{children}</h2>}
              placeholder="Type here..."
              allowedFeatures={[types.RichTextFeatures.Highlight]}
              renderHighlight={({ children }) => (
                <span className="text-themered">{children}</span>
              )}
            />
            <div className="flex flex-col">
              <HorizontalDivider className="my-4 bg-themegray-light" />
              <div className="flex mb-4">
                <img src={smallQuoteRed} alt="quote" className="h-5 w-5" />
              </div>
              <RichText
                propName="subheading"
                renderBlock={({ children }) => <h3>{children}</h3>}
                placeholder="Type here..."
                allowedFeatures={[types.RichTextFeatures.Highlight]}
                renderHighlight={({ children }) => (
                  <span className="text-themered">{children}</span>
                )}
              />
              <HorizontalDivider className="my-4 bg-themegray-light" />
            </div>
          </div>
          <div className="flex flex-col basis-1/2 content justify-between">
            <RichText
              propName="content"
              renderBlock={({ children }) => (
                <p className="content">{children}</p>
              )}
              placeholder="Type here..."
              allowedFeatures={[
                types.RichTextFeatures.Bold,
                types.RichTextFeatures.Highlight,
                types.RichTextFeatures.Link,
                types.RichTextFeatures.Italic,
                types.RichTextFeatures.Heading6,
                types.RichTextFeatures.Heading4,
              ]}
              renderHighlight={({ children }) => (
                <span className="text-themered">{children}</span>
              )}
            />
            <div
              className={`flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-3 ${
                btnMargin ? 'mt-8 sm:mt-24' : ''
              }`}
            >
              <Repeater propName="buttons" />
            </div>
          </div>
        </div>
      </LayoutContainer>
    </div>
  </section>
)

QuoteCta.schema = {
  name: 'quote-cta',
  label: 'Quote and Call to Action',
  getDefaultProps: () => ({
    paddingTop: true,
    paddingBottom: true,
    bgColor: { color: '#e1e8f1', className: 'bg-themegray-extralight' },
    btnMargin: true,
    headingId: '',
  }),
  sideEditProps: [
    {
      name: 'paddingTop',
      label: 'Padding Top',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'paddingBottom',
      label: 'Padding Bottom',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'White',
            value: { color: '#ffffff', className: 'bg-white' },
          },
          {
            label: 'Gray',
            value: { color: '#e1e8f1', className: 'bg-themegray-extralight' },
          },
        ],
      },
    },
    {
      name: 'btnMargin',
      label: 'Button Margin',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'headingId',
      label: 'Heading ID',
      type: types.SideEditPropType.Text,
    },
  ],
  repeaterItems: [
    {
      name: 'buttons',
      itemType: 'button',
      itemLabel: 'Button',
    },
    {
      name: 'banners',
      itemType: 'image',
      itemLabel: 'Banner',
      max: 1,
    },
  ],
}

export default QuoteCta
