import React from 'react'
import { types, RichText } from 'react-bricks/frontend'
import LayoutContainer from '../../components/LayoutContainer'

interface OneColumnContentProps {
  paddingTop?: boolean
  paddingBottom?: boolean
}

const OneColumnContent: types.Brick<OneColumnContentProps> = ({
  paddingTop,
  paddingBottom,
}) => (
  <section
    className={`w-screen ${paddingTop ? 'pt-20' : ''} ${
      paddingBottom ? 'pb-20' : 'pb-5'
    }`}
  >
    <LayoutContainer>
      <div className="content">
        <RichText
          propName="content"
          renderBlock={({ children }) => <p className="content">{children}</p>}
          placeholder="Type here..."
          allowedFeatures={[
            types.RichTextFeatures.Bold,
            types.RichTextFeatures.Highlight,
            types.RichTextFeatures.Link,
            types.RichTextFeatures.Italic,
            types.RichTextFeatures.Heading1,
            types.RichTextFeatures.Heading2,
            types.RichTextFeatures.Heading3,
            types.RichTextFeatures.Heading4,
            types.RichTextFeatures.Heading5,
            types.RichTextFeatures.Heading6,
            types.RichTextFeatures.OrderedList,
            types.RichTextFeatures.UnorderedList,
          ]}
          renderHighlight={({ children }) => (
            <span className="text-themered">{children}</span>
          )}
        />
      </div>
    </LayoutContainer>
  </section>
)

OneColumnContent.schema = {
  name: 'one-column-content',
  label: 'One Column Content Block',
  getDefaultProps: () => ({
    content: 'Replace this content',
    paddingTop: true,
    paddingBottom: true,
  }),
  sideEditProps: [
    {
      name: 'paddingTop',
      label: 'Padding Top',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'paddingBottom',
      label: 'Padding Bottom',
      type: types.SideEditPropType.Boolean,
    },
  ],
}

export default OneColumnContent
