import React from 'react'
import { types, Repeater, RichText } from 'react-bricks/frontend'
import LayoutContainer from '../../components/LayoutContainer'

interface ImageAndTextProps {
  paddingTop?: boolean
  paddingBottom?: boolean
}

const ImageAndText: types.Brick<ImageAndTextProps> = ({
  paddingTop,
  paddingBottom,
  ...rest
}) => (
  <section
    className={`w-screen ${paddingTop ? 'pt-20' : ''} ${
      paddingBottom ? 'pb-20' : ''
    }`}
    {...rest}
  >
    <LayoutContainer className="flex flex-col sm:flex-row">
      <div className="flex flex-col sm:basis-1/2 p-3 space-y-3">
        <Repeater propName="images" />
        <Repeater propName="videoThumbnails" />
      </div>
      <div className="flex flex-col sm:basis-1/2 p-3 content">
        <RichText
          propName="content"
          placeholder="Type here..."
          renderBlock={({ children }) => <p className="">{children}</p>}
          allowedFeatures={[
            types.RichTextFeatures.Highlight,
            types.RichTextFeatures.Bold,
            types.RichTextFeatures.Italic,
            types.RichTextFeatures.Link,
            types.RichTextFeatures.Heading6,
            types.RichTextFeatures.Heading2,
          ]}
          renderHighlight={({ children }) => (
            <span className="text-themered">{children}</span>
          )}
        />
      </div>
    </LayoutContainer>
  </section>
)

ImageAndText.schema = {
  name: 'image-and-text',
  label: 'Image(s) and Text',
  getDefaultProps: () => ({
    paddingTop: true,
    paddingBottom: true,
  }),
  sideEditProps: [
    {
      name: 'paddingTop',
      label: 'Padding Top',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'paddingBottom',
      label: 'Padding Bottom',
      type: types.SideEditPropType.Boolean,
    },
  ],
  repeaterItems: [
    {
      name: 'images',
      itemType: 'image',
      itemLabel: 'Image',
    },
    {
      name: 'videoThumbnails',
      itemType: 'video-thumbnail',
      itemLabel: 'Video Thumbnail',
    },
  ],
}

export default ImageAndText
