import { types } from 'react-bricks/frontend'
import PageHeader from './PageHeader'
import Hero from './Hero'
import Badge from './Badge'
import TwoColumnContent from './TwoColumnContent'
import Button from './Button'
import IconText from './IconText'
import Offerings from './Offerings'
import SocialIcon from './SocialIcon'
import BannerSection from './BannerSection'
import ImageAndText from './ImageAndText'
import VideoThumbnail from './VideoThumbnail'
import Image from './Image'
import VideoList from './VideoList'
import VideoListing from './VideoListing'
import ArrowCard from './ArrowCard'
import ArrowCards from './ArrowCards'
import VideoCarousel from './VideoCarousel'
import Testimonial from './Testimonial'
import Testimonials from './Testimonials'
import QuoteCta from './QuoteCta'
import ContactFormAndInfo from './ContactFormAndInfo'
import ContactForm from './ContactForm'
import PDFDownload from './PDFDownload'
import PDFList from './PDFList'
import OneColumnContent from './OneColumnContent'

const bricks: types.Brick<any>[] = [
  PageHeader,
  Hero,
  Badge,
  OneColumnContent,
  TwoColumnContent,
  Button,
  IconText,
  Offerings,
  SocialIcon,
  BannerSection,
  ImageAndText,
  VideoThumbnail,
  Image,
  VideoList,
  VideoListing,
  ArrowCard,
  ArrowCards,
  VideoCarousel,
  Testimonial,
  Testimonials,
  QuoteCta,
  ContactFormAndInfo,
  ContactForm,
  PDFDownload,
  PDFList,
]

export default bricks
