import React, { useState } from 'react'
import { types, Image } from 'react-bricks/frontend'
import ModalVideo from 'react-modal-video'

import playButton from '../../images/play-button.svg'

interface VideoThumbnailProps {
  videoId: string
}

const VideoThumbnail: types.Brick<VideoThumbnailProps> = ({
  videoId,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <ModalVideo
        channel="youtube"
        youtube={{ autoplay: 1, mute: 1 }}
        isOpen={open}
        videoId={videoId}
        onClose={() => setOpen(false)}
      />
      <div
        className="relative aspect-video w-full pb-8 sm:pb-0 sm:pr-4 lg:pr-12"
        {...rest}
      >
        <div className="relative w-full">
          <Image
            propName="thumbnail"
            alt="video thumbnail"
            noWrapper={true}
            imageClassName="h-full w-full object-cover object-center aspect-video"
            aspectRatio={16 / 9}
          />
          <div className="absolute inset-0 flex justify-center items-center">
            <img
              src={playButton}
              className="h-3/4 sm:h-1/2 hover:opacity-70 cursor-pointer"
              onClick={() => {
                if (videoId) {
                  setOpen(true)
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

VideoThumbnail.schema = {
  name: 'video-thumbnail',
  label: 'Video Thumbnail',
  getDefaultProps: () => ({
    thumbnail: {
      src: 'https://images.reactbricks.com/original/2c9cd623-120e-409f-b0c8-083efda05f76.jpg',
      placeholderSrc:
        'https://images.reactbricks.com/placeholder/2c9cd623-120e-409f-b0c8-083efda05f76.jpg',
      srcSet:
        'https://images.reactbricks.com/src_set/2c9cd623-120e-409f-b0c8-083efda05f76-939.jpg 939w,\nhttps://images.reactbricks.com/src_set/2c9cd623-120e-409f-b0c8-083efda05f76-800.jpg 800w,\nhttps://images.reactbricks.com/src_set/2c9cd623-120e-409f-b0c8-083efda05f76-400.jpg 400w,\nhttps://images.reactbricks.com/src_set/2c9cd623-120e-409f-b0c8-083efda05f76-200.jpg 200w',
      width: 939,
      height: 544,
      alt: 'image',
      seoName: 'image',
    },
  }),
}

export default VideoThumbnail
