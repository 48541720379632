import React from 'react'
import { types, Image, RichText } from 'react-bricks/frontend'

interface TestimonialProps {}

const Testimonial: types.Brick<TestimonialProps> = ({ ...rest }) => (
  <div
    className="flex flex-col space-y-8 sm:odd:flex-row-reverse sm:even:flex-row sm:justify-end sm:space-y-0 sm:space-x-8 z-10 w-full"
    {...rest}
  >
    <div className="flex justify-center items-center basis-3/12">
      <Image
        propName="image"
        alt="image"
        containerClassName="flex rounded-full overflow-hidden h-56 w-56 items-center justify-center drop-shadow-xl border-2"
        imageClassName="object-cover h-full w-full"
      />
    </div>
    <div className="relative flex justify-center items-center basis-9/12 w-full quote-accent content before:absolute before:bg-left-top before:-top-10 sm:before:-top-6 before:-left-2 sm:before:-left-12 z-0 before:w-16 before:h-16 before:bg-no-repeat before:bg-contain">
      <div className=" flex flex-col w-full bg-white rounded-lg p-10 content">
        <RichText
          propName="content"
          placeholder="Type here..."
          renderBlock={({ children }) => <p className="content">{children}</p>}
          allowedFeatures={[
            types.RichTextFeatures.Highlight,
            types.RichTextFeatures.Bold,
            types.RichTextFeatures.Italic,
            types.RichTextFeatures.Link,
            types.RichTextFeatures.Heading6,
            types.RichTextFeatures.Heading4,
          ]}
          renderHighlight={({ children }) => (
            <span className="text-themered">{children}</span>
          )}
        />
      </div>
    </div>
  </div>
)

Testimonial.schema = {
  name: 'testimonial',
  label: 'Testimonial',
  getDefaultProps: () => ({
    image: {
      src: 'https://images.reactbricks.com/original/6cea6789-fcc0-4764-b7c1-e4cb31aeea06.jpg',
      placeholderSrc:
        'https://images.reactbricks.com/placeholder/6cea6789-fcc0-4764-b7c1-e4cb31aeea06.jpg',
      srcSet:
        'https://images.reactbricks.com/src_set/6cea6789-fcc0-4764-b7c1-e4cb31aeea06-594.jpg 594w,\nhttps://images.reactbricks.com/src_set/6cea6789-fcc0-4764-b7c1-e4cb31aeea06-400.jpg 400w,\nhttps://images.reactbricks.com/src_set/6cea6789-fcc0-4764-b7c1-e4cb31aeea06-200.jpg 200w',
      width: 594,
      height: 479,
      alt: 'image',
      seoName: 'image',
    },
  }),
}

export default Testimonial
