import React from 'react'
import { types, RichText } from 'react-bricks'
import VideoThumbnail from './VideoThumbnail'

interface VideoListingProps {
  videoId: string
  textColor?: types.IColor
}

const VideoListing: types.Brick<VideoListingProps> = ({
  videoId,
  textColor,
  ...rest
}) => (
  <div className="flex flex-col sm:flex-row sm:space-x-12" {...rest}>
    <div className="flex flex-col space-y-4 basis-1/2">
      <VideoThumbnail videoId={videoId} />
    </div>
    <div className={`flex flex-col basis-1/2`}>
      <RichText
        propName="heading"
        renderBlock={({ children }) => (
          <h4 className={`mb-4 ${textColor?.className}`}>{children}</h4>
        )}
        placeholder="Type here..."
        allowedFeatures={[types.RichTextFeatures.Highlight]}
        renderHighlight={({ children }) => (
          <h4
            className="text-themered max-w-fit mb-10"
            style={{ lineHeight: 1.35 }}
          >
            <span className="relative shadow bg-white whitespace-pre-wrap">
              <span className="relative left-1.5 pr-2 text-themered">
                {children}
              </span>
            </span>
          </h4>
        )}
      />
      <RichText
        propName="content"
        renderBlock={({ children }) => (
          <p className={`content ${textColor?.className}`}>{children}</p>
        )}
        placeholder="Type here..."
        allowedFeatures={[
          types.RichTextFeatures.Bold,
          types.RichTextFeatures.Highlight,
          types.RichTextFeatures.Link,
          types.RichTextFeatures.Italic,
        ]}
        renderHighlight={({ children }) => (
          <span className="text-themered">{children}</span>
        )}
      />
    </div>
  </div>
)

VideoListing.schema = {
  name: 'video-listing',
  label: 'Video Listing',
  getDefaultProps: () => ({
    thumbnail: {
      src: 'https://images.reactbricks.com/original/2c9cd623-120e-409f-b0c8-083efda05f76.jpg',
      placeholderSrc:
        'https://images.reactbricks.com/placeholder/2c9cd623-120e-409f-b0c8-083efda05f76.jpg',
      srcSet:
        'https://images.reactbricks.com/src_set/2c9cd623-120e-409f-b0c8-083efda05f76-939.jpg 939w,\nhttps://images.reactbricks.com/src_set/2c9cd623-120e-409f-b0c8-083efda05f76-800.jpg 800w,\nhttps://images.reactbricks.com/src_set/2c9cd623-120e-409f-b0c8-083efda05f76-400.jpg 400w,\nhttps://images.reactbricks.com/src_set/2c9cd623-120e-409f-b0c8-083efda05f76-200.jpg 200w',
      width: 939,
      height: 544,
      alt: 'image',
      seoName: 'image',
    },
    textColor: { color: '#444648', className: 'text-themegray-text' },
  }),
  sideEditProps: [
    {
      name: 'thumbnail',
      label: 'Video Thumbnail',
      type: types.SideEditPropType.Image,
    },
    {
      name: 'videoId',
      label: 'YouTube Video ID',
      type: types.SideEditPropType.Text,
    },
    {
      name: 'textColor',
      label: 'Font Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'Light',
            value: { color: '#444648', className: 'text-themegray-text' },
          },
          {
            label: 'Dark',
            value: { color: '#ffffff', className: 'text-white' },
          },
        ],
      },
    },
  ],
}

export default VideoListing
