import React from 'react'
import { types, Repeater } from 'react-bricks/frontend'
import LayoutContainer from '../../components/LayoutContainer'
import HorizontalDivider from '../../components/HorizontalDivider'

interface VideoListProps {
  paddingTop?: boolean
  paddingBottom?: boolean
}

const VideoList: types.Brick<VideoListProps> = ({
  paddingTop,
  paddingBottom,
}) => (
  <section
    className={`w-screen ${paddingTop ? 'pt-20' : ''} ${
      paddingBottom ? 'pb-20' : 'pb-5'
    }`}
  >
    <LayoutContainer>
      <HorizontalDivider className="bg-themegray-light my-16" />
      <Repeater
        propName="videos"
        renderItemWrapper={(item) => (
          <>
            {item}
            <HorizontalDivider className="bg-themegray-light my-16" />
          </>
        )}
      />
    </LayoutContainer>
  </section>
)

VideoList.schema = {
  name: 'video-list',
  label: 'Video List',
  getDefaultProps: () => ({
    paddingTop: true,
    paddingBottom: true,
  }),
  sideEditProps: [
    {
      name: 'paddingTop',
      label: 'Padding Top',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'paddingBottom',
      label: 'Padding Bottom',
      type: types.SideEditPropType.Boolean,
    },
  ],
  repeaterItems: [
    {
      name: 'videos',
      itemType: 'video-listing',
      itemLabel: 'Video Listing',
    },
  ],
}

export default VideoList
