import React from 'react'
import { types, RichText, File } from 'react-bricks/frontend'
import { FiFilePlus } from 'react-icons/fi'
import { FaDownload } from 'react-icons/fa'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

// apparently needed: https://github.com/wojtekmaj/react-pdf/issues/677
import { pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

interface PDFDownloadProps {
  pdfFile: types.IFileSource
}

const PDFDownload: types.Brick<PDFDownloadProps> = ({ pdfFile, ...rest }) => {
  console.log(JSON.stringify(pdfFile, null, 2))
  return (
    <div
      className="flex flex-col-reverse space-y-reverse-8 sm:odd:flex-row-reverse sm:even:flex-row sm:justify-end sm:space-y-0 sm:space-x-8 z-10 w-full"
      {...rest}
    >
      <div className="flex justify-center items-center basis-1/2">
        <File
          propName="pdfFile"
          renderBlock={(file) =>
            file ? (
              <a
                className="relative group cursor-pointer"
                href={file.url}
                target="_blank"
                download
              >
                <div className="absolute inset-0 flex justify-center items-center z-50">
                  <div className="flex flex-col justify-center items-center aspect-square w-1/2 rounded-full invisible group-hover:visible group-hover:backdrop-blur-sm group-hover:shadow">
                    <FaDownload className="text-3xl mt-4 mb-1" />
                    <p className="text-sm">Download PDF</p>
                  </div>
                </div>
                <Document
                  file={file.url}
                  onLoadSuccess={() => console.log('loaded successfully')}
                  onLoadError={(error) =>
                    alert('Error while loading document! ' + error.message)
                  }
                  className="flex justify-center shadow-xl"
                >
                  <Page pageNumber={1} width={300} />
                </Document>
              </a>
            ) : (
              <div className="flex font-semibold h-full items-center ">
                <FiFilePlus className="mr-2" />
                Upload PDF
              </div>
            )
          }
          allowedExtensions={['.pdf']}
        />
      </div>
      <div className="flex justify-center items-center basis-1/2 flex-shrink-0">
        <div className=" flex flex-col w-full bg-white rounded-lg px-8 py-4 content">
          <RichText
            propName="content"
            placeholder="Type here..."
            renderBlock={({ children }) => (
              <p className="content">{children}</p>
            )}
            allowedFeatures={[
              types.RichTextFeatures.Highlight,
              types.RichTextFeatures.Bold,
              types.RichTextFeatures.Italic,
              types.RichTextFeatures.Link,
              types.RichTextFeatures.Heading3,
              types.RichTextFeatures.Heading4,
            ]}
            renderHighlight={({ children }) => (
              <span className="text-themered">{children}</span>
            )}
          />
        </div>
      </div>
    </div>
  )
}

PDFDownload.schema = {
  name: 'pdf-download',
  label: 'PDF Download',
  getDefaultProps: () => ({
    pdfFile: {
      name: 'Self discipline newsletter.pdf',
      size: 326.82,
      url: 'https://files.reactbricks.com/5e981c7e-0361-41e6-809c-2f6c631374e9/Self%20discipline%20newsletter.pdf',
    },
  }),
}

export default PDFDownload
