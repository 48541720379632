import React from 'react'
import { types } from 'react-bricks/frontend'
import DubsadoContactForm from '../../components/DubsadoContactForm'

interface ContactFormProps {}

const ContactForm: types.Brick<ContactFormProps> = () => (
  <div className="bg-themegray-400 rounded-lg">
    <DubsadoContactForm />
  </div>
)

ContactForm.schema = {
  name: 'contact-form',
  label: 'Contact Form',
}

export default ContactForm
