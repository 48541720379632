import React from 'react'
import { types, Image, useAdminContext } from 'react-bricks/frontend'

interface ImageProps {
  width: string
}

const ResponsiveImage: types.Brick<ImageProps> = ({ width, ...rest }) => {
  const { isAdmin } = useAdminContext()

  if (isAdmin) {
    return (
      <div className="relative" {...rest}>
        <Image
          propName="image"
          alt="image"
          containerClassName=""
          imageClassName={`${width}`}
        />
        {isAdmin && (
          <div className="absolute bottom-0 right-0 bg-gray-500 cursor-pointer rounded-tl-lg text-white px-4 py-1 hover:bg-gray-400 text-sm">
            Click to edit image attributes
          </div>
        )}
      </div>
    )
  }

  return (
    <Image
      propName="image"
      alt="image"
      containerClassName=""
      imageClassName={`${width}`}
      {...rest}
    />
  )
}

ResponsiveImage.schema = {
  name: 'image',
  label: 'Image',
  getDefaultProps: () => ({
    image: {
      src: 'https://images.reactbricks.com/original/6cea6789-fcc0-4764-b7c1-e4cb31aeea06.jpg',
      placeholderSrc:
        'https://images.reactbricks.com/placeholder/6cea6789-fcc0-4764-b7c1-e4cb31aeea06.jpg',
      srcSet:
        'https://images.reactbricks.com/src_set/6cea6789-fcc0-4764-b7c1-e4cb31aeea06-594.jpg 594w,\nhttps://images.reactbricks.com/src_set/6cea6789-fcc0-4764-b7c1-e4cb31aeea06-400.jpg 400w,\nhttps://images.reactbricks.com/src_set/6cea6789-fcc0-4764-b7c1-e4cb31aeea06-200.jpg 200w',
      width: 594,
      height: 479,
      alt: 'image',
      seoName: 'image',
    },
    width: 'w-full',
  }),
  sideEditProps: [
    {
      name: 'width',
      label: 'Width',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Select,
        options: [
          {
            label: '25%',
            value: 'w-1/4',
          },
          {
            label: '50%',
            value: 'w-1/2',
          },
          {
            label: '75%',
            value: 'w-3/4',
          },
          {
            label: '100%',
            value: 'w-full',
          },
        ],
      },
    },
  ],
}

export default ResponsiveImage
