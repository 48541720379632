import React from 'react'

interface HorizontalDividerProps {
  className?: string
}

export default function HorizontalDivider({
  className,
}: HorizontalDividerProps) {
  return (
    <div
      className={`my-6 h-0.5 bg-white w-full ${className ? className : ''}`}
    ></div>
  )
}
