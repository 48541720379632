import React from 'react'
import { types, Repeater } from 'react-bricks/frontend'
import LayoutContainer from '../../components/LayoutContainer'

interface TestimonialsProps {
  paddingTop?: boolean
  paddingBottom?: boolean
}

const Testimonials: types.Brick<TestimonialsProps> = ({
  paddingTop,
  paddingBottom,
}) => (
  <section
    className={`w-screen ${paddingTop ? 'pt-20' : ''} ${
      paddingBottom ? 'pb-20' : 'pb-5'
    }`}
  >
    <div className="w-screen bg-themegray-extralight">
      <LayoutContainer className="relative w-full min-h-[500px] quote-background before:absolute before:bg-right before:right-5 before:sm:right-20 overflow-hidden before:w-96 before:h-96 before:bg-no-repeat before:bg-contain">
        <div className="w-full flex flex-col space-y-20 pt-52 pb-20">
          <Repeater propName="testimonials" />
        </div>
      </LayoutContainer>
    </div>
  </section>
)

Testimonials.schema = {
  name: 'testimonials',
  label: 'Testimonials',
  getDefaultProps: () => ({
    paddingTop: true,
    paddingBottom: true,
  }),
  sideEditProps: [
    {
      name: 'paddingTop',
      label: 'Padding Top',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'paddingBottom',
      label: 'Padding Bottom',
      type: types.SideEditPropType.Boolean,
    },
  ],
  repeaterItems: [
    {
      name: 'testimonials',
      itemType: 'testimonial',
      itemLabel: 'Testimonial',
    },
  ],
}

export default Testimonials
