import React from 'react'
import { types, RichText } from 'react-bricks/frontend'
import Button from './Button'

import arrow from '../../images/link-arrow-1.svg'

interface ArrowCardProps {
  bgColor: types.IColor
  arrowColor: types.IColor
  btnBgColor: types.IColor
  btnTextColor: types.IColor
  btnArrowColor: types.IColor
  btnPadding: boolean
  btnRelativeUrl?: string
}

const ArrowCard: types.Brick<ArrowCardProps> = ({
  bgColor,
  arrowColor,
  btnBgColor,
  btnTextColor,
  btnArrowColor,
  btnPadding,
  btnRelativeUrl,
  ...rest
}) => (
  <div
    className={`relative flex flex-col justify-between flex-grow rounded-xl pt-10 px-8 pb-8 arrow-card before:z-0 before:bg-right before:absolute before:-top-8 before:-right-6 overflow-hidden before:w-full before:h-64 before:bg-no-repeat ${bgColor?.className} ${arrowColor?.className}`}
    {...rest}
  >
    <div className="z-10">
      <RichText
        propName="heading"
        renderBlock={({ children }) => (
          <h3 className="text-2xl sm:text-4xl mb-4">{children}</h3>
        )}
        placeholder="Type here..."
        allowedFeatures={[types.RichTextFeatures.Highlight]}
        renderHighlight={({ children }) => (
          <h3
            className="text-themered max-w-fit text-2xl sm:text-4xl mb-10"
            style={{ lineHeight: 1.35 }}
          >
            <span className="relative shadow bg-white whitespace-pre-wrap">
              <span className="relative left-1.5 pr-2 text-themered">
                {children}
              </span>
            </span>
          </h3>
        )}
      />
      <RichText
        propName="content"
        renderBlock={({ children }) => (
          <p className="content mb-12">{children}</p>
        )}
        placeholder="Type here..."
        allowedFeatures={[
          types.RichTextFeatures.Bold,
          types.RichTextFeatures.Highlight,
          types.RichTextFeatures.Link,
          types.RichTextFeatures.Italic,
        ]}
        renderHighlight={({ children }) => (
          <span className="text-themered">{children}</span>
        )}
      />
    </div>
    <div className="z-10">
      <Button
        bgColor={btnBgColor}
        textColor={btnTextColor}
        arrowColor={btnArrowColor}
        padding={btnPadding}
        relativeUrl={btnRelativeUrl}
      />
    </div>
  </div>
)

ArrowCard.schema = {
  name: 'arrow-card',
  label: 'Arrow Card',
  getDefaultProps: () => ({
    bgColor: { color: '#e1e8f1', className: 'bg-themegray-extralight' },
    arrowColor: {
      color: '#cad2da',
      className: 'arrow-light',
    },
    btnTextColor: { color: '#ffffff', className: 'text-white' },
    btnArrowColor: { color: '#ffffff', className: 'text-white' },
    btnBgColor: { color: '#9c2a3b', className: 'bg-themered' },
    btnPadding: true,
  }),
  sideEditProps: [
    {
      name: 'bgColor',
      label: 'Card Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'White',
            value: { color: '#e1e8f1', className: 'bg-themegray-extralight' },
          },
          {
            label: 'Gray',
            value: { color: '#cad2da', className: 'bg-themegray-light' },
          },
        ],
      },
    },
    {
      name: 'arrowColor',
      label: 'Card Arrow Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'White',
            value: { color: '#cad2da', className: 'arrow-light' },
          },
          {
            label: 'Gray',
            value: { color: '#444648', className: 'arrow-dark' },
          },
        ],
      },
    },
    {
      name: 'btnBgColor',
      label: 'Button Background Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'White',
            value: { color: '#ffffff', className: 'bg-white' },
          },
          {
            label: 'Red',
            value: { color: '#9c2a3b', className: 'bg-themered' },
          },
        ],
      },
    },
    {
      name: 'btnTextColor',
      label: 'Button Text Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'White',
            value: { color: '#ffffff', className: 'text-white' },
          },
          {
            label: 'Red',
            value: { color: '#9c2a3b', className: 'text-themered' },
          },
          {
            label: 'Gray - Text',
            value: { color: '#444648', className: 'text-themegray-text' },
          },
          {
            label: 'Gray - Heading',
            value: { color: '#252729', className: 'text-themegray-heading' },
          },
        ],
      },
    },
    {
      name: 'btnArrowColor',
      label: 'Button Arrow Color',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            label: 'White',
            value: { color: '#ffffff', className: 'text-white' },
          },
          {
            label: 'Red',
            value: { color: '#9c2a3b', className: 'text-themered' },
          },
        ],
      },
    },
    {
      name: 'btnPadding',
      label: 'Button Horizontal Padding',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'btnRelativeUrl',
      label: 'Button Relative Url',
      type: types.SideEditPropType.Text,
    },
  ],
}

export default ArrowCard
