import React from 'react'
import { RichText, types } from 'react-bricks/frontend'

interface BadgeProps {
  text: string
}

const Badge: types.Brick<BadgeProps> = ({ ...rest }) => (
  <div {...rest}>
    <RichText
      propName="text"
      renderBlock={({ children }) => (
        <h6 className="text-sm sm:text-base uppercase text-themered font-semibold">
          {children}
        </h6>
      )}
      placeholder="Type here..."
      allowedFeatures={[types.RichTextFeatures.Bold]}
    />
  </div>
)

Badge.schema = {
  name: 'badge',
  label: 'Badge',
  getDefaultProps: () => ({
    text: 'Label',
  }),
}

export default Badge
