import React from 'react'

interface LayoutContainerProps {
  children: React.ReactNode
  className?: string
}

const LayoutContainer: React.FC<LayoutContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={`w-screen px-5 lg:px-16 max-w-[1300px] mx-auto ${
        className ? className : ''
      }`}
    >
      {children}
    </div>
  )
}

export default LayoutContainer
