import React from 'react'
import { types, Repeater, RichText } from 'react-bricks/frontend'
import HorizontalDivider from '../../components/HorizontalDivider'
import LayoutContainer from '../../components/LayoutContainer'

interface TwoColumnContentProps {
  paddingTop?: boolean
  paddingBottom?: boolean
}

const TwoColumnContent: types.Brick<TwoColumnContentProps> = ({
  paddingTop,
  paddingBottom,
}) => (
  <section
    className={`w-screen ${paddingTop ? 'pt-20' : ''} ${
      paddingBottom ? 'pb-20' : 'pb-5'
    }`}
  >
    <LayoutContainer>
      <div className="flex flex-col sm:flex-row sm:space-x-4">
        <div className="flex flex-col space-y-4 basis-1/2">
          <Repeater propName="badges" />
          <RichText
            propName="heading"
            renderBlock={({ children }) => (
              <h2 className="text-2xl sm:text-5xl">{children}</h2>
            )}
            placeholder="Type here..."
            allowedFeatures={[types.RichTextFeatures.Highlight]}
            renderHighlight={({ children }) => (
              <span className="text-themered">{children}</span>
            )}
          />
          <Repeater propName="buttons" />
        </div>
        <div className="flex flex-col basis-1/2">
          <HorizontalDivider className="bg-themegray-light sm:mt-0" />
          <RichText
            propName="content"
            renderBlock={({ children }) => (
              <p className="content">{children}</p>
            )}
            placeholder="Type here..."
            allowedFeatures={[
              types.RichTextFeatures.Bold,
              types.RichTextFeatures.Highlight,
              types.RichTextFeatures.Link,
              types.RichTextFeatures.Italic,
            ]}
            renderHighlight={({ children }) => (
              <span className="text-themered">{children}</span>
            )}
          />
        </div>
      </div>
    </LayoutContainer>
  </section>
)

TwoColumnContent.schema = {
  name: 'two-column-content',
  label: 'Two Column Content Block',
  getDefaultProps: () => ({
    heading: 'Replace this heading',
    content: 'Replace this content',
    paddingTop: true,
    paddingBottom: true,
  }),
  sideEditProps: [
    {
      name: 'paddingTop',
      label: 'Padding Top',
      type: types.SideEditPropType.Boolean,
    },
    {
      name: 'paddingBottom',
      label: 'Padding Bottom',
      type: types.SideEditPropType.Boolean,
    },
  ],
  repeaterItems: [
    {
      name: 'badges',
      itemType: 'badge',
      itemLabel: 'Badge',
      max: 1,
    },
    {
      name: 'buttons',
      itemType: 'button',
      itemLabel: 'Button',
      max: 2,
    },
  ],
}

export default TwoColumnContent
