import React from 'react'
import { types, RichText, Image } from 'react-bricks/frontend'

interface IconTextProps {}

const IconText: types.Brick<IconTextProps> = ({ ...rest }) => (
  <div className="flex items-center space-x-4" {...rest}>
    <Image
      propName="icon"
      alt="icon"
      containerClassName="flex flex-shrink-0 h-20 sm:h-24 w-20 sm:w-24"
      imageClassName="h-full w-full object-cover object-left"
    />
    <RichText
      propName="text"
      placeholder="Type here..."
      renderBlock={({ children }) => <div className="flex">{children}</div>}
    />
  </div>
)

IconText.schema = {
  name: 'icon-text',
  label: 'Icon with Text',
  getDefaultProps: () => ({
    text: 'placeholder',
    icon: {
      src: 'https://images.reactbricks.com/original/bf810c4e-3bfe-451b-b8fa-cb8be9820a55.svg',
      placeholderSrc:
        'https://images.reactbricks.com/original/bf810c4e-3bfe-451b-b8fa-cb8be9820a55.svg',
      srcSet: '',
      width: 103,
      height: 102,
      alt: 'icon',
      seoName: '',
    },
  }),
}

export default IconText
